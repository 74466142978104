import React from 'react'
import Masthead from '../components/about-us/masthead'
import Recipe from '../components/about-us/recipe'
import Modus from '../components/about-us/modus'
import ModusVivendi from '../components/about-us/modusvivendi'
import 'bootstrap/dist/css/bootstrap.min.css';


import Layout from '../components/layout'

const AboutUs = () => (
  <Layout>
    <Masthead />
    <Recipe />
    <Modus 
      title="Modus operandi"
      head="It all starts with listening to your pitch." 
      desc1="We then go back to our lab, do our research - not just on the problems
      but also on the market pulse, business trends & the needs of the end user."
      desc2="We present you with a practical blueprint of the solution with its scope and tech-stack."
      textColor="black"
      bgColor="rgb(255, 218, 165)"
    />
    <Modus 
      title="Faster release cycles"
      head={["Small team. Quick milestones.", <br/>, "Continuous communication. Periodic delivery."]} 
      desc1="We are a lean, agile and a no bullshit team. At the end of every sprint, you enjoy a vertical slice of the actual software."      
      desc2={["Our", <a target='_blank' class='text-links' href='https://www.culturemonkey.io/employee-engagement/employee-feedback-in-the-workplace-2022/'> employee feedback</a>, " portal is always open.", <br/>, "This makes us nimble and helps us get to ", <u style={{fontWeight: '500'}}><i>Version 1.0</i></u>, " fast and early."]}
      textColor="rgb(13, 42, 83)"
      bgColor="rgb(206, 206, 206)"
    />
    <ModusVivendi />    
  </Layout>
)

export default AboutUs
