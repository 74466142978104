import React from 'react'
import FullPageBlock from '../full-page-block'
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css";
import "animate.css/animate.min.css";

const Recipe = () => (
  <FullPageBlock style={{ textColor : "#FFFFFF", bgColor : "rgb(57, 152, 106)"}}>
    <div className="left"></div>
    <div className="right">
      <div className="heading">
        <ScrollAnimation animateIn="subtleFadeInLeft" duration="1">
        <hr />
        </ScrollAnimation>
        <div className="text">
          <ScrollAnimation animateIn="fadeIn" duration="1">
            Recipe for success
          </ScrollAnimation>
        </div>
      </div>
      <div className="desc recipe-desc">
        <ScrollAnimation animateIn="subtleFadeInUp" duration="1" delay="400">
          <div className="desc-item">We let our clients dream big and complex.</div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="subtleFadeInUp" duration="1" delay="1000">
          <div className="desc-item">We simplify the solutions with the<br/> right technology & great user experience.</div>
        </ScrollAnimation>
      </div>
    </div>
  </FullPageBlock>
)

export default Recipe
