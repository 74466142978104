import React from 'react'
import FullPageBlock from '../full-page-block'
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css"

const Modus = (props) => (
  <FullPageBlock style={{ textColor : props.textColor, bgColor : props.bgColor}}>
    <div className="fullpage">
      <div className="heading">
        <div className="text">
          <ScrollAnimation animateIn="fadeIn">
            {props.title}
          </ScrollAnimation>
        </div>
        <ScrollAnimation animateIn="subtleFadeInLeft">
          <hr style={{ backgroundColor: 'black' }} />
        </ScrollAnimation>
      </div>
      <div className="modusoperandi">
        <div className="head">
          <ScrollAnimation animateIn="subtleFadeInUp">
            {props.head}
          </ScrollAnimation>
        </div>
        <div className="modus-desc">
          <ScrollAnimation animateIn="subtleFadeInUp" delay="300">
            <div className="separator"></div>
            <div className="modus-desc--content">
              {props.desc1}
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="subtleFadeInUp" delay="700">
            <div className="separator"></div>
            {/* <div className="modus-desc--content" dangerouslySetInnerHTML={{ __html: props.desc2 }} /> */}
            <div className="modus-desc--content">
              {props.desc2}
            </div>
          </ScrollAnimation>

        </div>
      </div>
    </div>
  </FullPageBlock>
)

export default Modus
