import React from 'react'
import FullPageBlock from '../full-page-block'
import wecare from '../../images/wecare.jpg';
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css"
import "animate.css/animate.min.css";


const ModusVivendi = (props) => (
  <FullPageBlock className="modusvivendi-block" style={{ textColor : props.textColor, bgColor : "#FFFFFF", height: '120vh'}}>
    <div className="fullpage">
      <div className="modus">
        <div className="modusvivendi heading">
          <hr style={{ backgroundColor: 'black' }} />
          <div className="text">
            Modus Vivendi
          </div>
          <div className="desc">
            <ScrollAnimation animateIn="subtleFadeInRight" duration="1">
              Effy is a team of smart, happy, humble, considerate and responsible folks whose touch/quality/vibe cascades into the software that they write.
            </ScrollAnimation>
          </div>
        </div>
        <ScrollAnimation animateIn="subtleFadeInLeft" duration="1" animateOnce="true">
          <div className="wecare-img">
            <img src={wecare} alt="We care and play 8 ball." />
          </div>
        </ScrollAnimation>
      </div>
      <div className="wecare">
        <div className="wecare-left">
          <div className="wecare-title">
            We Care!
          </div>
          <div className="wecare-desc">
            When we say we care,<br/>
            we mean, we care for you career,<br/>
            your family, your hobbies,<br/>
            your time at gym, and your cat.<br/><br/>
            There you go, we've said it!
          </div>
        </div>
        <ScrollAnimation animateIn="subtleBounceInUp" duration="0.5" delay="500" animateOnce="true">
          <div className="wecare-right">
            Staying late at office is not hard work!<br/>
            It is poor decision making.
            <br/>
            <br/>
            Shut down your computers at reasonable time, be gone;
            Go home & live your life the way you always wanted.
            <br/>
            <br/>
            <ul>
              <li>No leave policy</li>
              <li>No work hours tracking</li>
              <li>Work from anywhere</li>
              <li>Mandatory vacation</li>
              <li>Half days on Wednesdays - 4.5 days work-week.</li>
            </ul>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  </FullPageBlock>
)

export default ModusVivendi
