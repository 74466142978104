import React from 'react'
import FullPageBlock from '../full-page-block'


const Masthead = () => (
  <FullPageBlock style={{ textColor : "white", bgColor : "#333333"}}>
    <div className="masthead-blur masthead-blur--about-us"></div>
    <div className="masthead-text">
      People are complex. Software doesn't need to be.<br/>And we understand this better.
    </div>
  </FullPageBlock>
)

export default Masthead
